import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import LayoutCentred from '../components/styled_components/style-centred'

const FourOhFourBanner = styled.section`
  background-position: center center;
  background-size: cover;
  color: #fff;
  display: block;
  height: 70vh;
  max-height: 400px;
  overflow: hidden;
  position: relative;
  width: 100%;
  &:before {
    content: '';
    display: block;
    padding-bottom: 33.333%;
    width: 100%;
    z-index: 2;
  }
  div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  h1 {
    font-size: 3rem;
    font-weight: normal;
    margin: 0;
    padding: 0 0 0.8rem;
    text-align: center;
  }
  h2 {
    font-size: 2rem;
    font-weight: normal;
    margin: 0;
    padding: 0;
    text-align: center;
  }
`

const NotFoundPage = () => (
  <Layout>
    <FourOhFourBanner className="fourohfour__banner">
      <div>
        <h1>
          <span className="text--yellow">404:</span> PAGE NOT FOUND
        </h1>
      </div>
    </FourOhFourBanner>
    <LayoutCentred>
      <div>
        <p className="text--center">
          It seems the page you're looking for may have been moved or removed.
        </p>
        <p className="text--center">
          Head back to our <a href="/">homepage</a>.
        </p>
      </div>
    </LayoutCentred>
  </Layout>
)

export default NotFoundPage
